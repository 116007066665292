import React from 'react';
import { ExpandedGroupMembersList } from '../ExpandedGroupMembersList';
import { ExpandedGroupPanelListViewProps } from './ExpandedGroupPanelListView.types';
import { Loader } from 'components/lib/Loader';
import EmptyData from 'components/EmptyData';
import NoMatchesFound from 'components/NoMatchesFound';
import { useIntl } from 'react-intl';
import { useUsersAndGroupsExpandablePickerStyles } from 'components/UsersAndGroupsSelection/UsersAndGroupsExpandableSelect/components/UsersAndGroupsExpandablePicker/UsersAndGroupsExpandablePicker.styles';
import { useExpandedGroupPanelListViewStyles } from './ExpandedGroupPanelListView.styles';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ExpandedUserGroupPanel } from '../../ExpandedUserGroupPanel';

/**
 * Represents the list view of the {@link ExpandedUserGroupPanel}
 */
export const ExpandedGroupPanelListView = ({
  isInitialLoadComplete,
  isSyncGroupMembers,
  noPermissionToListMembers,
  results,
  search,
  userGroup,
}: ExpandedGroupPanelListViewProps) => {
  const intl = useIntl();
  const styles = useExpandedGroupPanelListViewStyles();
  const sharedStyles = useUsersAndGroupsExpandablePickerStyles();

  if (!isInitialLoadComplete) {
    return (
      <div className={sharedStyles.loadingContainer}>
        <Loader size='small' />
      </div>
    );
  }

  if (noPermissionToListMembers) {
    return (
      <EmptyData
        title=''
        className={styles.emptyDataContainer}
        description={intl.formatMessage({
          id: 'misc.noPermissionToListMembers',
          defaultMessage:
            'You do not have permission to list members of this user group. Please contact the System Administrator.',
        })}
      />
    );
  }

  if (results.length === 0 && userGroup.num_of_members > 0) {
    return <NoMatchesFound />;
  }

  if (results.length === 0 && userGroup.num_of_members === 0) {
    return (
      <EmptyData
        title=''
        className={styles.emptyDataContainer}
        description={intl.formatMessage({
          id: 'misc.noMembersOfGroup',
          defaultMessage: 'There are no members of this group',
        })}
      />
    );
  }

  return (
    <ExpandedGroupMembersList
      items={results}
      textToHighlight={search.visibleSearchText}
      isSyncGroupMembers={isSyncGroupMembers}
    />
  );
};
