import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelectedResourceContext } from 'contexts/SelectedResourceContext';
import ErrorComponent from 'components/ErrorComponent';
import { Loader } from 'components/lib/Loader';
import useSummaryPanelStyles from './styles';
import usePreviousState from 'hooks/usePreviousState';
import useData from 'hooks/useData';
import { generatePath } from 'react-router-dom';
import { OBJECT_RECORD_DETAILS, OBJECT_CLASS_DETAILS } from 'utils/endpoints';
import { extractFormDataFromRecord } from 'components/formBuilder/utils/formPreviewUtils';
import { AvatarItem } from 'components/lib/Avatar/Avatar.types';
import { useDispatch, useSelector } from 'react-redux';
import useDynamicSchema from 'components/formBuilder/hooks/useDynamicSchema';
import FormPreview2 from 'components/FormPreview2';
import { FormPreview2RefProps } from 'components/FormPreview2/types';
import migrateFormDefinitionToSupportedVersion from 'components/formBuilder/migrateDefinition';
import { ObjectRecordDetails } from 'utils/types/api/objectRecords.types';
import { getObjectRecordUsers } from 'store/actions/objectRecordActions';
import {
  getObjectRecordUsersData,
  getObjectRecordUsersError,
  getObjectRecordUsersLoading,
} from 'store/selectors/objectRecordSelectors';
import { applyFilesMetaToFormFields } from 'components/FormPreview2/utils';
import { FORM_PREVIEW_WRAPPER, SIDE_PANEL } from 'utils/elementsIds';
import { useIntl } from 'react-intl';
import PermissionDenied from 'components/PermissionDenied';
import useRefetchResource from 'hooks/useRefetchResource';
import { RefetchResources } from 'contexts/types';
import { SummaryPanelProps, SummaryPanelSource } from './types';
import { DraggableComponents } from 'components/formBuilder/formBuilder/InputsContainer/enums';
import schemaHasField from 'utils/functions/schemaHasField';
import { getObjectClassesResponse } from 'store/selectors/objectClassesSelectors';
import { apiCall } from 'utils/api';
import { ObjectClassesResponse } from 'utils/types/api/objectClasses.types';

const SummaryPanel = ({
  dataSource = SummaryPanelSource.DATA,
  RenderHeader,
  RenderPermissionDenied,
}: SummaryPanelProps) => {
  const dispatch = useDispatch();
  const classes = useSummaryPanelStyles({});
  const intl = useIntl();
  const formRef = useRef<FormPreview2RefProps>(null);
  const {
    selectedResource: {
      record: { recordId: recordIdFromData = undefined } = {},
    } = {},
    additionalSelectedResource: {
      record: { recordId: recordIdFromAdditionalData = undefined } = {},
    } = {},
  } = useSelectedResourceContext();

  const recordId =
    dataSource === SummaryPanelSource.ADDITIONAL_DATA
      ? recordIdFromAdditionalData
      : recordIdFromData;

  const ownersError = useSelector(getObjectRecordUsersError);
  const ownersLoading = useSelector(getObjectRecordUsersLoading);
  const { results: ownersData = [] } = useSelector(
    getObjectRecordUsersData(recordId)
  );

  const prevId = usePreviousState(recordId);

  const [data, { loading: dataLoading, error, fetchData }] = useData<
    ObjectRecordDetails
  >('', {
    fetchOnLoad: false,
  });

  const objectClassResponse = useSelector(getObjectClassesResponse) || {};
  const [
    { data: objectClass, loading: objectClassLoading, error: objectClassError },
    setObjectClass,
  ] = useState<ObjectClassesResponse>(objectClassResponse);

  const { data_schema: dynamicSchema, ui_schema: dynamicUiSchema } =
    objectClass?.display_configuration?.recordSummary || {};

  const { schema, uiSchema, loading } = useDynamicSchema(
    dynamicSchema,
    dynamicUiSchema,
    objectClass?.id
  );

  const [hasViewOwnersPermission, setHasViewOwnersPermission] = useState(false);

  const { supportedSchema, supportedUiSchema } = useMemo(() => {
    const {
      schema: supportedSchema,
      uiSchema: supportedUiSchema,
    } = migrateFormDefinitionToSupportedVersion(
      JSON.stringify(schema),
      JSON.stringify(uiSchema)
    );

    return {
      supportedSchema,
      supportedUiSchema,
    };
  }, [schema, uiSchema]);

  const initialFormData = useMemo(() => {
    if (uiSchema) {
      const formData = { ...extractFormDataFromRecord(data || {}).formData };
      const formDataWithFiles = applyFilesMetaToFormFields({
        uiSchema: JSON.stringify(uiSchema),
        formData,
        filesMeta: data?._meta.labels.files,
      });

      return { ...formDataWithFiles, owners: ownersData as AvatarItem[] };
    }

    return undefined;
  }, [uiSchema, data, ownersData]);

  const refetchData = useCallback(() => {
    if (recordId !== undefined && prevId !== recordId) {
      fetchData(generatePath(OBJECT_RECORD_DETAILS, { id: recordId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordId]);

  useEffect(() => {
    (async () => {
      //fetch only when object_class for object_record is different than object_class in store
      if (
        data &&
        +data.object_class !== objectClass?.id &&
        !objectClassLoading
      ) {
        setObjectClass({ loading: true });
        const res = await apiCall.get(
          generatePath(OBJECT_CLASS_DETAILS, { id: data.object_class })
        );
        setObjectClass({ loading: false, data: res.data });
      }
    })();
  }, [data, objectClass, objectClassLoading]);

  useEffect(() => {
    refetchData();
  }, [refetchData]);

  const refetchResourceCallback = useCallback(
    (refetchFnData?: MappedObject<unknown>) => {
      const fnData = refetchFnData as { recordId?: number };

      if (!refetchFnData || fnData.recordId?.toString() === recordId) {
        refetchData();
      }
    },
    [refetchData, recordId]
  );

  useRefetchResource(
    RefetchResources.Records,
    refetchResourceCallback,
    'recordSummary'
  );

  useEffect(() => {
    if (Number(data?.object_class) !== Number(objectClass?.id)) {
      return;
    }
    setHasViewOwnersPermission(!!data?._meta.permissions.view_owners);
    if (
      data?._meta.permissions.view_owners &&
      schemaHasField(schema, DraggableComponents.Owners)
    ) {
      dispatch(getObjectRecordUsers(data.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schema, data]);

  if (
    !loading &&
    !dataLoading &&
    !ownersLoading &&
    (objectClassError || ownersError || error)
  ) {
    return <ErrorComponent error={ownersError?.status || error?.status} />;
  }

  if (
    !loading &&
    !dataLoading &&
    !ownersLoading &&
    !objectClassLoading &&
    !objectClass?.display_configuration?.recordSummary?.data_schema?.enabled
  ) {
    return (
      <>
        {RenderHeader && objectClass && data && (
          <RenderHeader objectClass={objectClass} objectRecord={data} />
        )}
        {RenderPermissionDenied ? (
          <RenderPermissionDenied />
        ) : (
          <PermissionDenied
            title={intl.formatMessage({
              id: 'summaryPanel.noSummaryViewConfigured',
              defaultMessage: 'Not configured',
            })}
            description={intl.formatMessage({
              id: 'summaryPanel.noSummaryView',
              defaultMessage: 'Object class has no summary view configured.',
            })}
          />
        )}
      </>
    );
  }

  return (
    <div className={classes.container}>
      <Loader
        spinning={objectClassLoading || loading || dataLoading || ownersLoading}
      >
        <div className={classes.panelWrapper}>
          {RenderHeader && objectClass && data && (
            <RenderHeader objectClass={objectClass} objectRecord={data} />
          )}
          {!(objectClassLoading || loading || dataLoading || ownersLoading) ? (
            <FormPreview2
              key={supportedUiSchema}
              schema={supportedSchema}
              uischema={supportedUiSchema}
              initialValues={initialFormData}
              omitDefaultValues
              ref={formRef}
              inPlaceEditMode
              readOnly
              {...{ hideOwners: !hasViewOwnersPermission }}
              additionalFieldProps={{
                identifier: data?.object_name,
                recordId: data?.id,
                classId: data?.object_class,
                meta: data?._meta,
                shouldDownloadFileOnClick: true,
              }}
              widgetsPopupContainer={() =>
                document.getElementById(SIDE_PANEL) ||
                document.getElementById(FORM_PREVIEW_WRAPPER) ||
                document.body
              }
            />
          ) : null}
        </div>
      </Loader>
    </div>
  );
};

export default SummaryPanel;
