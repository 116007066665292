import { RefObject, useCallback, useState } from 'react';
import { FormDataValue } from 'components/FormPreview/types';
import { FlatTokenDataProps } from 'components/FormBuilderModal/types';
import { addFieldPrefixes } from 'components/FormPreview/utils';
import { apiCall } from 'utils/api';
import { OBJECT_RECORD_DETAILS } from 'utils/endpoints';
import { showUnhandledErrorToast } from 'features/toasts/utils/showUnhandledErrorToast';
import { generatePath } from 'react-router-dom';
import useSuccessToast from '../../../../hooks/useSuccessToast';
import { ExtendedUiSchema } from 'components/FormPreview/customFields/ObjectField/types';
import { FormValue, Meta } from 'alx-dynamic-form';
import { FormPreview2RefProps } from 'components/FormPreview2/types';
import { AvatarItem } from 'components/lib/Avatar/Avatar.types';
import { ObjectRecordDetails } from 'utils/types/api/objectRecords.types';
import { applyFilesMetaToFormFields } from 'components/FormPreview2/utils';

const useEditRecordForm = (
  recordId: string,
  formRef: RefObject<FormPreview2RefProps>,
  openModal: () => void,
  uiSchema: ExtendedUiSchema | undefined
) => {
  const [isSaving, setIsSaving] = useState(false);
  const [errors, setErrors] = useState<MappedObject<string[]> | undefined>();
  const displayToast = useSuccessToast();
  const [initialFormData, setInitialFormData] = useState<
    MappedObject<FormValue> | undefined
  >();

  const onSubmit = async (
    formData: MappedObject<FormDataValue>,
    _fileData: FlatTokenDataProps
  ) => {
    setIsSaving(true);
    setErrors(undefined);
    const parsedData = {
      ...addFieldPrefixes(formData), //converts all keys from "fieldName" to "field_fieldName" (api requirement)
    };

    // api requires null values instead of undefined
    const fixedData = Object.entries(parsedData).reduce(
      (prev, [key, value]) => ({
        ...prev,
        [key]: value === undefined ? null : value,
      }),
      {}
    );

    try {
      await apiCall.patch(
        generatePath(OBJECT_RECORD_DETAILS, {
          id: recordId,
        }),
        fixedData
      );

      displayToast();

      return true;
    } catch (error) {
      const data = (error as any)?.response?.data || {};
      const errors = data as MappedObject<string[]>;

      setErrors(errors);

      if (
        errors &&
        Object.values(errors).some(item =>
          item.includes('This field must be unique.')
        )
      ) {
        openModal();
      } else if (errors) {
        showUnhandledErrorToast(error);
      }

      return false;
    } finally {
      setIsSaving(false);
    }
  };

  const handleSetInitialFormData = useCallback(
    (record: ObjectRecordDetails, owners: AvatarItem[]) => {
      const formDataWithFilesMeta = applyFilesMetaToFormFields({
        uiSchema: JSON.stringify(uiSchema),
        formData: record.formData,
        filesMeta: record._meta.labels.files,
      });

      setInitialFormData({
        ...formDataWithFilesMeta,
        owners: owners as Meta[],
      });
    },
    [uiSchema]
  );

  return {
    onSubmit,
    isSaving,
    initialFormData,
    setInitialFormData: handleSetInitialFormData,
    errors,
  };
};

export default useEditRecordForm;
